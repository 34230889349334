import { ref, computed, onMounted } from "vue";
import * as API from "@/API/spotCheckingConfiguration";
import { Moment } from 'moment';
import moment from 'moment';
import { message } from 'ant-design-vue';

export interface TableData {
    id: string;
    contractActivationDay: string;
    contractActiveDate: Moment;
}

const useContractActivationDays = () => {
    const formRef = ref()

    const year = ref<string>()

    const basicColData = computed(() => {
        return [
            {
                title: "Contract Activation Days",
                dataIndex: "contractActivationDay",
                slots: { customRender: "contractActivationDay" }
            },
            {
                title: "Active Date",
                dataIndex: "contractActiveDate",
                slots: { customRender: "contractActiveDate" }
            }
        ]
    })
    const form = ref<{ tableData: TableData[] }>({
        tableData: []
    })
    const getBasicTable = () => {
        const params = {
            id: '1'
        }
        API.getSettingInfo(params).then(data => {
            form.value.tableData = [{
                id: data.id,
                contractActivationDay: data.contractActivationDay,
                contractActiveDate: moment(data.contractActiveDate)
            }]
        })
    }
    
    const saveCell = (callback: Function, index: number, cell: string) => {
        formRef.value.validate([['tableData', index, cell]]).then(() => {
            callback(false)
        }).catch(() => {
            callback(true)
        })
    };

    const saveClick = () => {
        formRef.value.validate().then(() => {
            const params = {
                contractActiveDate: form.value.tableData[0].contractActiveDate.format('YYYYMMDD'),
                contractActivationDay: form.value.tableData[0].contractActivationDay,
                id: form.value.tableData[0].id
            }
            API.setContractActivationDays(params).then(res => {
                if (res.code === '0') {
                    message.success('Successfully Save!')
                    form.value.tableData = []
                    getBasicTable()
                } else {
                    message.error(res.message)
                }
            })
        })
    }
    onMounted(() => {
        getBasicTable()
    })
    return {
        year,
        basicColData,
        formRef,
        form,
        saveCell,
        saveClick,
    }
}
export default useContractActivationDays